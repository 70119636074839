import { ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, Checkbox, ModalFooter, Button } from "@chakra-ui/react";
import InputMask from 'react-input-mask';
import styles from "../Modal.module.scss"
interface Props {
    banco: { id: string, nome: string },
    operacao: { id: string, nome: string },
    setStep: (step: number) => void
}
export function getFormContent({ banco, operacao, setStep }: Props) {

    if (banco.nome === "Banco PAN") {
        if (operacao.nome === "Simulação FGTS") {
            return <><FormControl mt={4}>
                <FormLabel>Data de Nascimento</FormLabel>
                <Input type="date" />
            </FormControl>

                <FormControl mt={4}>
                    <FormLabel htmlFor="cpf-simulacao">CPF</FormLabel>
                    <InputMask
                        mask="999.999.999-99"
                        placeholder="000.000.000-00"
                        name="cpf-simulacao"
                        id="cpf-simulacao"
                        className={styles.input_mask}
                    />
                </FormControl>

                <FormControl mt={4}>
                    <FormLabel>Valor (Campo não obrigatório)</FormLabel>
                    <Input
                        type="number"
                        step={1000}
                    >

                    </Input>
                </FormControl>

                <Checkbox defaultChecked mt={4}>Incluir Seguro</Checkbox>

                <ModalFooter p={0}>
                    <Button mr={3} onClick={() => setStep(2)}>
                        Voltar
                    </Button>
                    <Button colorScheme='blue' onClick={() => { }}>
                        Simular
                    </Button>
                </ModalFooter></>
        } else {
            return <></>
        }
    } else {
        return <></>
    }

}