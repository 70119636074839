import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import styles from "./Modal.module.scss";

import InputMask from 'react-input-mask';
import { useEffect, useState } from "react";
import { Step1 } from "./Steps/Step1";
import { Step2 } from "./Steps/Step2";
import { Step3 } from "./Steps/Step3";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}
interface IData {
    id: string,
    nome: string
}


export const ModalSimulation = ({ isOpen, onClose }: Props) => {

    const [step, setStep] = useState(1);
    const [content, setContent] = useState<any>();

    const [operacao, setOperacao] = useState<IData>();
    const [banco, setBanco] = useState<IData>();

    const [leed, setLeed] = useState(0);

    useEffect(() => {
        switch (step) {
            case 1:
                setContent(<Step1
                    step={setStep}
                    setLeed={setLeed}
                />);
                break;
            case 2:
                setContent(<Step2
                    step={setStep}
                    setData={setData}
                    leed={leed}
                />);
                break;
            case 3:
                setContent(<Step3
                    operacao={operacao ? operacao : { id: '0', nome: '' }}
                    banco={banco ? banco : { id: '0', nome: '' }}
                    setStep={setStep}
                />)
                break;
        }

        // setContent(<Step2
        //     step={setStep}
        //     setData={setData}
        //     leed={leed}
        // />);

    }, [step]);

    const setData = (data: { operacao: IData, banco: IData }) => {
        setOperacao(data.operacao);
        setBanco(data.banco);
    }

    return (
        <Modal
            size={'xl'}
            isOpen={isOpen}
            onClose={() => {
                onClose();
                setStep(1);
            }}
        >
            <ModalOverlay />
            <ModalContent>
                {content}
            </ModalContent>
        </Modal>
    );
}