import styles from './ServicesCard.module.scss';
import { useEffect } from 'react';
import { IServicesCardProps } from '../../../../interfaces/IServicesCardProps';

export const ServicesCard = ({ src, slug, title, text }: IServicesCardProps) => {
    useEffect(() => {
        const calcTranslate = (knowMoreDivLink: HTMLElement) => {
            const parentNode = knowMoreDivLink.parentNode as HTMLElement | null;
            const grandparentNode = parentNode?.parentNode as HTMLElement | null;
            const cardWidth = grandparentNode?.clientWidth;
            if (cardWidth) {
                const calculo = cardWidth / 2 + 10;
                knowMoreDivLink.style.transform = `translateX(${calculo}px) scale(1)`;
            }
        };

        const showKnowMore = (card: Element) => {
            const knowMoreDivLink = card.querySelector(`.${styles.knowmore__div}`);
            if (knowMoreDivLink instanceof HTMLElement) {
                knowMoreDivLink.classList.add(`${styles.knowmore__active}`);
                calcTranslate(knowMoreDivLink);
            }
        };

        const hideKnowMore = (card: Element) => {
            const knowMoreDivLink = card.querySelector(`.${styles.knowmore__div}`);
            if (knowMoreDivLink instanceof HTMLElement) {
                knowMoreDivLink.classList.remove(`${styles.knowmore__active}`);
                knowMoreDivLink.style.transform = 'translateX(50px) scale(0.5)';
            }
        };

        const cards = document.querySelectorAll(`.${styles.card}`);
        cards.forEach((card) => {
            card.addEventListener('mouseover', () => showKnowMore(card));
            card.addEventListener('mouseout', () => hideKnowMore(card));
        });
    }, []);

    return (
        <div className={styles.card}>
            <div className={styles.card__info}>
                <img
                    src={require(`../../../../assets/images/services/${src}.png`)}
                    alt=""
                    className={styles.card__img}
                />
                <p className={styles.card__title}>{title}</p>
                <p className={styles.card__text}>{text}</p>
            </div>
            <div className={styles['knowmore__div--mobile']}>
                <a
                    className={styles['knowmore__link--mobile']}
                    href={`/servicos/${slug}`}
                >
                    Saiba mais
                </a>
            </div>
            <div className={styles.knowmore}>
                <div className={styles.knowmore__div}>
                    <a href={`/servicos/${slug}`} className={styles.knowmore__link}>
                        Saiba mais
                    </a>
                </div>
            </div>
        </div>
    );
};
