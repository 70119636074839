import { ModalHeader, ModalCloseButton, Button, ModalBody, FormControl, FormLabel, Input, ModalFooter } from "@chakra-ui/react";
import styles from "./../Modal.module.scss";
import InputMask from 'react-input-mask';
import axios from "axios";
import { useState } from "react";
import config from "../../../../config";
import toast from "react-hot-toast";

interface Props {
    step: (step: number) => void;
    setLeed: (leed: number) => void;
}


export const Step1 = ({ step, setLeed }: Props) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const handleStep1 = () => {

        if (!name || !phone) {
            toast.error("Preencha todos os campos");
            return;
        }

        if (phone.includes('_')) {
            toast.error("Preencha o telefone corretamente");
            return;
        }

        axios.post(`${config.apiUrl}/simulacoes/step1.php`, {
            name: name,
            phone: phone,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }

        })
            .then((response) => {
                if (response.data.status === 200) {
                    setLeed(parseInt(response.data.i));
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                step(2);
            });

    }

    return (<>
        <ModalHeader>Simulação</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
            <h1 className={styles.titles}>
                Faça você mesmo
            </h1>
            <FormControl>
                <FormLabel>Nome</FormLabel>
                <Input
                    placeholder='Digite seu nome'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormControl>

            <FormControl mt={4}>
                <FormLabel htmlFor="telefone-simulacao">Telefone</FormLabel>
                <InputMask
                    mask="(99) 99999-9999"
                    placeholder="(00) 00000-0000"
                    name="telefone-simulacao"
                    id="telefone-simulacao"
                    className={styles.input_mask}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </FormControl>
            <ModalFooter px={0}>
                <Button colorScheme='blue' onClick={handleStep1}>
                    Continuar
                </Button>
            </ModalFooter>

            <h1 className={styles.titles}>OU</h1>

            <a
                className={styles.btn_simulacao}
                href="https://api.whatsapp.com/send?phone=5513997121143"
                target="_blank"
                rel="noreferrer"
            >
                <div>Entre em contato</div>
            </a>

        </ModalBody>

    </>);
}