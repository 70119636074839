import { Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { Blog } from './pages/Blog';
import { ServicePage } from './components/Blog/ServicePage/ServicePage';
import { Layout } from './components/Layout';

const LayoutRoutes = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/simulacao?" element={<Home />} />
                <Route path="/servicos" element={<Blog />} />
                <Route path="/servicos/:slug" element={<ServicePage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Layout>
    );
};

export const MainRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/*" element={<LayoutRoutes />} />
            </Routes>
        </>
    );
};
