
import { Button, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { getFormContent } from "./getFormContent";

interface Props {
    banco: { id: string, nome: string },
    operacao: { id: string, nome: string },
    setStep: (step: number) => void
}

export const Step3 = ({ banco, operacao, setStep }: Props) => {

    return (<>
        <ModalHeader>{operacao.nome} - {banco.nome}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
            {getFormContent({ banco, operacao, setStep })}
        </ModalBody>
    </>);
}