import { ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, Select, ModalFooter, Button } from "@chakra-ui/react";
import styles from "../Modal.module.scss"
import { FormEvent, useEffect, useState } from "react";
import bancosPorOperacaoData from "../../../../data/bancosPorOperacao.json";
import toast from "react-hot-toast";
import axios from "axios";
import config from "../../../../config";
import { Loader } from "../../../Loader/Loader";
interface Props {
    step: (step: number) => void;
    setData: (data: { operacao: IData, banco: IData }) => void;
    leed: number;
}
interface IData { id: string, nome: string }
interface BancosPorOperacao {
    [key: string]: {
        id: number;
        bancos: {
            id: number;
            nome: string;
        }[];
    };
}

export const Step2 = ({ step, setData, leed }: Props) => {

    const [bancoSelecionado, setBancoSelecionado] = useState<IData | null>(null);
    const [operacaoSelecionada, setOperacaoSelecionada] = useState<IData | null>(null);
    const [bancosPorOperacao, setBancosPorOperacao] = useState<BancosPorOperacao>({});

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.post(`${config.apiUrl}/simulacoes/step2.php`, {
            m: 'g'
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setBancosPorOperacao(response.data.op);
                setIsLoading(false);
            }
        }).catch(() => toast.error(`Erro ao carregar bancos por operação`));
    }, []);

    const nextStep = () => {
        if (!bancoSelecionado || !operacaoSelecionada) {
            toast.error("Preencha todos os campos");
            return;
        }
        setData({ operacao: operacaoSelecionada, banco: bancoSelecionado });
        axios.post(`${config.apiUrl}/simulacoes/step2.php`, {
            "m": "l",
            "l": leed,
            "b": bancoSelecionado.id,
            "o": operacaoSelecionada.id
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        step(3);
    };

    return (<>
        <ModalHeader>Simulação</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
            {isLoading ? <Loader size="100px" color="blue" /> :
                <><FormControl mt={3}>
                    <FormLabel>Operação</FormLabel>
                    <Select
                        placeholder="Selecione a operação que deseja simular"
                        value={operacaoSelecionada?.id}
                        onChange={(e) => {
                            setOperacaoSelecionada({ id: e.target.value, nome: e.target.options[e.target.selectedIndex].text });
                        }}
                    >
                        {Object.keys(bancosPorOperacao).map((operacao) => (
                            <option key={bancosPorOperacao[operacao].id} value={bancosPorOperacao[operacao].id}>
                                {operacao}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                    <FormControl mt={3}>
                        <FormLabel>Banco</FormLabel>
                        <Select
                            placeholder="Selecione o banco"
                            value={bancoSelecionado?.id}
                            onChange={(e) => {
                                // if (operacaoSelecionada?.id) {
                                //     console.log(bancosPorOperacao[operacaoSelecionada.nome]);
                                //     // console.log(operacaoSelecionada.nome);
                                //     // console.log(bancosPorOperacao[operacaoSelecionada.nome]);
                                // }
                                const banco = operacaoSelecionada?.id && bancosPorOperacao[operacaoSelecionada.nome]?.bancos.find((banco) => banco.id === parseInt(e.target.value));
                                setBancoSelecionado(banco ? { id: banco.id.toString(), nome: banco.nome } : null);
                            }}
                            isDisabled={!operacaoSelecionada?.id}
                        >
                            {operacaoSelecionada?.nome && bancosPorOperacao[operacaoSelecionada.nome]?.bancos.map((banco) => (
                                <option key={banco.id} value={banco.id}>
                                    {banco.nome}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <ModalFooter px={0}>
                        <Button colorScheme='blue' onClick={nextStep}>
                            Continuar
                        </Button>
                    </ModalFooter></>}
        </ModalBody>
    </>);
}