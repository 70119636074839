import { MainRoutes } from './routes';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
    return (
        <ChakraProvider>
            <BrowserRouter>
                <MainRoutes />
            </BrowserRouter>
        </ChakraProvider>
    );
}

export default App;
